.w-content{
    width: max-content;
}
.btn-ocean {
    background-image: linear-gradient(to right, #02AAB0 0%, #00CDAC  51%, #02AAB0  100%);
}

.btn-ocean:hover {
    color: #fff;
    text-decoration: none;
}
.btn-yellow {
    background-image: linear-gradient(to right, #ffb347 0%, #ffcc33  51%, #ffb347  100%);
}

.btn-yellow:hover {
    color: #fff;
    text-decoration: none;
}
.fs-13{
    font-size: 13px;
}
